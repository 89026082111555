<template>
  <b-card
    no-body
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <span class="lead collapse-title">
          <b-media class="media">
            <div class="nameClass">
              <b-link>
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.left="title"
                  class="d-block font-weight-bolder text-truncate"
                >
                  {{ title }}
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div
          class="d-flex"
        >
          <div>
            <div
              v-if="spinner[id]"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <vs-switch
              v-else
              :id="id"
              :value="state"
              circle
              class="switches"
              :style="`background-color: ${state ? '#00E265' : '#FF0000'};color:white;`"
              @click="updateSwitchState($event, id, state)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Disable</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Enable</span>
            </vs-switch>
          </div>
        </div>
        <div>
          <feather-icon
            :id="id"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            style="margin: 8px"
            @click="review($event)"
          />
          <b-tooltip
            :disabled.sync="canEditCampaign"
            :target="'tooltip-icon-disable' + id"
          >
            <span>Running Campaign cannot be edited! </span>
          </b-tooltip>
          <span :id="'tooltip-icon-disable' + id">
            <feather-icon
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover
              icon="Edit2Icon"
              :class="state? 'disableIcon':'null'"
              size="16"
              style="margin: 8px; cursor: pointer"
              @click="editBannerFunc($event, state)"
            />
          </span>
          <b-tooltip
            :disabled.sync="canEditCampaign"
            :target="'delete-icon-disable' + id"
          >
            <span>Running Campaign cannot be deleted! </span>
          </b-tooltip>
          <span :id="'delete-icon-disable' + id">
            <feather-icon
              :id="id"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover
              :icon="icons"
              :class="state? 'disableIcon':'null'"
              style=" padding: 6px ; cursor: pointer"
              size="28"
              @click="deleteBannerFunc($event, state)"
            />
          </span>
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <div class="device">
        Screens
      </div>
      <b-row class="mx-0">
        <b-col
          v-for="(data,index) in campaign.banner_campaigns_has_devices"
          :key="index"
          class="flex-grow-0 align-items-left my-1 mx-0"
        >
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                size="32"
                :src="require('@/assets/images/simiicons/device.svg')"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.device.name }}
            </span>
            <small class="text-muted">{{ data.device.mac_address }}</small>
          </b-media>
        </b-col>
      </b-row>
      <!--      devices-group-->
      <div class="device">
        Screens Groups
      </div>
      <b-row class="mx-0">
        <b-col
          v-for="(data,index) in campaign.banner_campaign_has_group"
          :key="index"
          class="flex-grow-0 align-items-left my-1 mx-0"
        >
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                size="32"
                :src="require('@/assets/images/simiicons/device.svg')"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.device_groups.name }}
            </span>
            <small class="text-muted">{{ data.device_groups.mac_address }}</small>
          </b-media>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BLink,
  BImg,
  BRow,
  BCol,
  VBToggle,
  BSpinner,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import { showErrorMessage, showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BLink,
    BImg,
    BRow,
    BCol,
    BSpinner,
    BTooltip,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['isVisible', 'title', 'icons', 'icon', 'src', 'time', 'id', 'edit', 'campaign', 'state'],
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      required,
      spinner: {},
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    canEditCampaign: {
      get() {
        return !this.state
      },
      set() {
        //
      },
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    deleteBannerFunc(e, state) {
      e.preventDefault()
      e.stopPropagation()
      if (!state) {
        this.$emit('deleteCampaign', { id: this.id, bannerCampaign: this.campaign })
      }
    },
    editBannerFunc(e, state) {
      e.preventDefault()
      e.stopPropagation()
      if (!state) {
        this.$emit('editCampaign', { id: this.id })
      }
    },
    switchBannerFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.switchs = !this.switchs
    },
    timeDiv(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    async updateSwitchState(e, id, state) {
      e.preventDefault()
      e.stopPropagation()
      const payload = {
        userHasGroupId: this.$route.params.userId,
        bannerId: id,
      }
      Vue.set(this.spinner, `${id}`, true)
      if (state) {
        try {
          const success = await this.$store.dispatch('bannerAds/editBannerAdStateDisable', { ...payload })
          if (success.data.statusCode === 200) {
            this.$emit('getAllBannerCampaigns')
            showToast('Internal Network Ad', 'Internal Network Ad state has been disabled successfully!')
            Vue.set(this.spinner, `${id}`, false)
          }
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Banner Ad', message.toString(), 'danger')
            Vue.set(this.spinner, `${id}`, false)
          }
        }
      } else {
        try {
          const success = await this.$store.dispatch('bannerAds/editBannerAdStateEnable', { ...payload })
          if (success.data.statusCode === 200) {
            this.$emit('getAllBannerCampaigns')
            showToast('Internal Network Ad', 'Internal Network Ad state has been disabled successfully!')
            Vue.set(this.spinner, `${id}`, false)
          }
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Banner Ad', message.toString(), 'danger')
            Vue.set(this.spinner, `${id}`, false)
          }
        }
      }
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.campaign.banner_campaign_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
  },
}
</script>
<style scoped>
.switches {
  border-radius: 25px;
}
.device{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.time{
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #414141;
  font-weight: 500;
  font-family: "Montserrat";
  margin-top: 4px;
  min-width: 240px;
}

.nameClass {
  min-width: 200px;
  max-width: 400px;
@media (max-width: 848px) {
  min-width: 200px;
  max-width: 200px;
}
}
.disableIcon{
  color:#80808080;
}
</style>
