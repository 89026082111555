<template>
  <div>
    <Teleport to="#header-buttons">
      <div id="tooltip-button-disable">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="bannerCampaignLoading"
          @click="addCampaign"
        >
          Add Campaign
        </b-button>
      </div>
    </Teleport>
    <div class="d-flex justify-content-end mt-0" />
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
        class="mt-2"
      >
        <b-skeleton-wrapper :loading="bannerCampaignLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <UserCollapseItem
            v-for="(bannerCampaigns, index) in allUserBannerCampaigns"
            :id="bannerCampaigns.id"
            :key="index"
            :title="bannerCampaigns.name"
            :time="bannerCampaigns.time"
            :campaign="bannerCampaigns"
            :state="bannerCampaigns.is_active"
            icon="EditIcon"
            icons="TrashIcon"
            :src="null"
            @deleteCampaign="deleteCampaign"
            @editCampaign="editCampaign"
            @getAllBannerCampaigns="getAllUserBannerCampaigns"
          >
            <div class="heading-elements">
              <b-table
                ref="bannerAdsMediaTable"
                :items="bannerCampaigns.banner_campaign_has_media"
                responsive
                selectable
                :select-mode="selectMode"
                :fields="tableColumns"
                show-empty
                class="float: right"
                th-class="text"
                @row-selected="onRowSelected($event, index)"
              >
                <!-- Column: Media -->
                <template #cell(MEDIA)="data">
                  <b-media no-body>
                    <span class="mr-2 ml-0">
                      {{ data.index + 1 }}
                    </span>
                    <b-media-aside class="mr-1 ml-0">
                      <b-img
                        :src="getThumbnailOfFile(data.item.media)"
                        class="image"
                        rounded
                        alt="data.json"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-link>
                        <span class="media-body">
                          {{ data.item.media.name }}
                        </span>
                      </b-link>
                    </b-media-body>
                  </b-media>
                </template>
                <!-- Column: Duration -->
                <template
                  #cell(DURATION)="data"
                  class="BannerMode"
                >
                  <span class="text-nowrap">
                    {{ data.item.media.duration }}
                  </span>
                </template>
                <!-- Column: Type -->
                <template
                  #cell(TYPE)="data"
                  class="BannerMode center"
                >
                  <div style="text-align: center">
                    <b-img
                      width="30px"
                      height="30px"
                      style="padding: 7px"
                      :src="data.item.media.type_main === MediaType.IMAGE ? require('@/assets/images/simiicons/Image.svg') : require('@/assets/images/simiicons/Video.svg')"
                    />
                    <span class="text-nowrap">
                      {{ data.item.media.type }}
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
          </UserCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div>
      <div class="text-nowrap" />
    </div>
  </div>
</template>

<script>
import {
  BMedia, BTable, BImg, BMediaBody, BMediaAside, BSkeletonWrapper, BSkeleton, BLink,
  BButton,
} from 'bootstrap-vue'
import Teleport from 'vue2-teleport'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import UserCollapseItem from '@/components/admin/UserCollapseItem.vue'
import {
  confirmDeletion, getThumbnailOfFile, showErrorMessage, showToast,
} from '@/common/global/functions'

export default {
  components: {
    UserCollapseItem,
    AppCollapse,
    BTable,
    BMedia,
    BImg,
    BMediaBody,
    BMediaAside,
    BSkeletonWrapper,
    BSkeleton,
    BLink,
    Teleport,
    BButton,
  },
  data() {
    return {
      MediaType,
      bannerCampaignLoading: false,
      allUserBannerCampaigns: [],
      selectMode: 'single',
      switch2: false,
      tableColumns: [{ key: 'MEDIA' }, { key: 'DURATION' }, { key: 'TYPE', thClass: 'text-center' }],
      tableRefIndex: 0,
    }
  },
  mounted() {
    this.getAllUserBannerCampaigns()
  },
  methods: {
    getAllUserBannerCampaigns() {
      this.bannerCampaignLoading = true
      this.$axios
        .get(`admin/banner-campaign/${(this.$route.params.userId)}`)
        .then(({ data: { data } }) => {
          this.allUserBannerCampaigns = data
          this.bannerCampaignLoading = false
        }).catch(error => ({ error }))
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    addCampaign() {
      this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads/add-campaigns`)
    },
    async onRowSelected(items, index) {
      if (items && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      this.$refs.bannerAdsMediaTable[index].clearSelected()
    },
    deleteCampaign(obj) {
      this.id = obj.id
      confirmDeletion().then(async result => {
        if (result.value) {
          this.deleteBannerAd(this.id)
        }
      })
    },
    editCampaign(obj) {
      this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads/edit-ads/${obj.id}`)
    },
    async deleteBannerAd(id) {
      const payload = {
        userHasGroupId: this.$route.params.userId,
        bannerId: id,
      }
      try {
        const success = await this.$store.dispatch('bannerAds/deleteBannerAds', { ...payload })
        if (success.data.statusCode === 200) {
          await this.getAllUserBannerCampaigns()
          showToast('Delete Internal Nwetwork Ad', 'Internal Network Ad has been deleted successfully!')
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Delete Internal Network Ad', message.toString(), 'danger')
        }
      }
    },
  },
}
</script>

<style>
.image {
  width: 32px;
  height: 32px;
  border: 2px solid#ececec;
  border-radius: 5px !important;
  box-shadow: 0 1px 7px#0000002e;
}
.center{
  float: right!important;
}
</style>
